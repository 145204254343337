@import "../themes/_footer_base";
@include footer-base;

.footer {
    position: relative;
    background-color: $color-light-gray-pruple;
    color: $color-black-font;
    font-size: $font-size-base + 2px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    overflow: hidden;
}

.footer-content {
    margin: 20px auto;
    display: block;
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: $screen-sm-min) {
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        gap: 10px;
    }
}

.footer-about {
    @include make-md-column(8);
}

.footer-widget {
    width: 200px;
    @include make-md-column(3);
}

.footer-logo {
    width: 51px;
    height: 52px;
    background-image: url("../Images/layout/krone-logo-kreis-b.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.footer-nav {
    @include make-md-column(12);

    .footer-nav-list {
        justify-content: flex-end;
    }
    @media (max-width: $screen-sm-min) {
        .footer-nav-list {
            flex-wrap: wrap;
            justify-content: center;
            column-gap: 15px;
            row-gap: 10px;
        }
    }

    a:hover {
        color: inherit;
    }

    li {
        display: inline-block;
        margin-left: 50px;
        line-height: 21px;
        
        @media (max-width: $screen-sm) {
            margin-left: 0;
        }
        
        a {
            font-weight: $font-weight-normal;
            color: $color-black-font;
            font-size: 16px;
            letter-spacing: 0.2rem;
        }
    }
}
