.notification {
    position: relative;
    display: flex;
    padding: 5px 30px;
    width: 100%;
    min-height: 50px;
    z-index: 33;
    background-color: $color-purple;

    .row {
        display: flex;
    }
    .notification-logo {
        position: relative;
        height: 100%;
        min-width: 100px;


        @media (min-width: $screen-md) {
            display: none;
        }

        &::before {
            display: flex;
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 75px;
            width: 80px;
            background-image: url("../Images/layout/alpenkoenig_typo_Nav+footer.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: center;
            z-index: 2;
        }

        &::after {
            position: absolute;
            top: -5px;
            left: -10px;
            display: block;
            content: "";
            width: 100px;
            height: 110px;
            background-color: #fff;
            z-index: 1;
            box-shadow: 0.5px 0.5px 0px 0.5px rgba(0,0,0,0.1);
        }
    }
    .notification-content {
        @extend .container;
        color: #fff;
        line-height: 2.5rem;
        padding: 5px 5px 5px 15px;
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: center;
        width: auto;

        @media (min-width: $screen-lg-min) {
            line-height: 4rem;
        }
        
        @media (max-width: $screen-sm) {
            padding: 2px 0;
        }
        

        p {
            margin-bottom: 0;
            
            @media (max-width: $screen-sm) {
                font-size: 16px;
            }
        }

        .neos-contentcollection {
            width: 100%;
        }
    }
    
    @media (max-width: $screen-md) {
        display: none;
    }
    
    &.mobile {
        display: none;
        
        @media (max-width: $screen-md) {
            display: block;
            padding: 20px 30px 10px;
        }
    }
}
