%btn-brand-primary {
    font-family: $font-family-sans-serif;
    background-color: $color-partner-widget;
    font-weight: $font-weight-medium;
    padding: 8px 50px;
    color: #fff !important;
    font-size: 19px;
    width: max-content;
    display: inline-block;
    transition: all ease-in-out 0.25s;
    
    &:hover {
        background-color: rgba($color:  $color-partner-widget, $alpha: 0.8);
        color: white;
        transition: all ease-in-out 0.25s;
    }

    @media (max-width: $screen-sm-max) {
      padding: 8px 28px;
      font-size: 18px;
    }
}

.btn-brand-primary {
    @extend %btn-brand-primary;
}