.room-collection-wrapper {
    .neos-contentcollection {
        display: grid;
        gap: 30px;
        justify-content: center;
        margin: 0 3%;

        &.partnerItem-3 {
            grid-template-columns: repeat(3, 1fr);
            
            @media (max-width: $screen-lg) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &.partnerItem-4 {
            grid-template-columns: repeat(4, 1fr);
            
            @media (max-width: 1440px) {
                grid-template-columns: repeat(2, 500px);
            }
            
            @media (max-width: $screen-lg) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: $screen-md) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &.partnerItem-2 {
            grid-template-columns: repeat(2, 500px);
            
            @media (max-width: $screen-md) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &.partnerItem-1 {
            grid-template-columns: repeat(1, 500px);
        }
    }

    .room-item, .teaser-item {
        text-align: center;
        background-color: white;
        box-shadow: 0 -3px 16px rgba(0, 0, 0, 0.1);

        > div {
            &:last-child {
                padding: 24px;
            }
        }

        .teaser-image {
            width: 100%;
            height: 240px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .neos-nodetypes-text-with-image {
            margin-top: 15px;
        }

        h3 {
            font-size: 22px;
            margin-top: 2px;
            margin-bottom: 24px;
        }
        
        .btn-brand-primary {
            margin-top: 5px;
        }

        .details {
            padding: 10px 0;
            border-top: 1px solid $color-purple;
            border-bottom: 1px solid $color-purple;
            margin-bottom: 24px;

            p {
                margin-bottom: 0;
            }
        }

        .neos-nodetypes-image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        figure {
            height: 100%;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                transition: transform 0.2s;
                height: 100%;
                width: 200px;
            }
        }
    }
}
