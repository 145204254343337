@mixin line-splitted-bottom() {
  &.line-splitted-bottom {
    background: transparent url('../Images/layout/line-splitted-green.svg') bottom center repeat-x;
    padding-bottom: 45px;
    background-size: 12px 7px;
    margin-bottom: 25px;
  }
}

@mixin line-splitted-top-bottom() {
  &.line-splitted-top-bottom {
    background-image: url('../Images/layout/line-splitted-green.svg'), url('../Images/layout/line-splitted-green.svg');
    background-position: 0 0, 0 bottom;
    background-repeat: repeat-x, repeat-x;
    background-size: 12px 7px;
    padding-bottom: 5px;
    padding-top: 9px;
  }
}

@mixin remove-padding-from-row() {
  .row {
    margin-left: 0;
    margin-right: 0;

    .grid-content-col:first-of-type {
      padding-left: 0;
    }
  }
}
