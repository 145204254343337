.languages {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  z-index: 30;
  
  @media (max-width: $screen-xs) {
    right: 15%;
  }
  
  @media (max-width: 320px) {
    right: 18%;
  }
}

.languages-list {
  @extend .list-inline;
  font-size: 16px;
  margin-bottom: 0;
  
  li {
    position: relative;
    padding-left: 8px;
    padding-right: 8px;

    &:first-child {
      &::after {
        content: '';
        background-color: $color-black;
        width: 2px;
        height: 18px;
        position: absolute;
        right: 0;
        top: 3px;
      }
    }
  }

  a {
    color: $color-black;
  }

  .current a {
    font-weight: 500;
  }
}
