.grid-content {
  width: 100%;
  padding: 90px 0;
  @extend .container;
  
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  figcaption p {
    text-align: left;
    padding-top: 8px;
    margin-bottom: 0;
  }

  @include line-splitted-bottom();
  @include line-splitted-top-bottom();

  &.bg-purple {
    @include remove-padding-from-row();
    background-color: $color-purple;
    padding: 100px 0;

    h2 {
      color: #fff;
    }

    .neos-nodetypes-text {
      color: #fff;

      a {
        color: #fff;
      }
      
      &.bulletTable {
        li {
          border-top: 1px solid white;
          
          &:last-child {
            border-bottom: 1px solid white;
          }
        }
      }
    }

    @media (max-width: $screen-xs-max) {
     .row .grid-content-col:first-of-type {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;
      }
    }
  }

  &.bg-light-gray {
    @include remove-padding-from-row();
    background-color: $color-gray;
    padding: 50px 15px !important;

    @media (min-width: $screen-md-min) {
      padding: 90px 0 !important;
    }
  }

  .centered {

    h1, h2, h3, h4, p, a {
      text-align: center;
    }
  }

  &.margin-top-section {
    margin-top: 100px;
  }

  &.bg-green {
    @include remove-padding-from-row();
    background-color: $color-light-gray-pruple;
    padding: 100px 0;

    h1, h2, h3, h4, p, ul, li, a {
      color: $color-black;
    }
    
    .neos-nodetypes-text {
      &.bulletTable {
        li {
          border-top: 1px solid $color-black;
          
          &:last-child {
            border-bottom: 1px solid $color-black;
          }
        }
      }
    }

    .room-collection-wrapper {
      p, h3 {
        color: $color-black;
        
        a {
          color: $color-black;
        }
      }
    }
  }
  
  &.video {
    @media (max-width: $screen-md-max) {
      .grid-content-col {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .line-splitted-bottom {
    @include line-splitted-bottom();
  }

  .quote {
    background-image: url('../Images/layout/quote.svg');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 80px;
    padding-left: 100px;
    margin-left: -100px;
  }

  &.arrow-white {
    @media (min-width: $screen-md-min) {
      background-image: url('../Images/layout/arrow-heart-white-reverse.svg');
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 150px;
    }
  }
  
  &.flexbox {
     .headstart-nodetypes-gridcontent {
      > .neos-contentcollection {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &.welcome {

    h1 {
      color: $color-black;
      font-size: $font-size-h2;
      margin-top: 0;
    }

    p {
      text-align: left;
    }

    .neos-nodetypes-image {
      width: auto;
      height: auto;
      padding-bottom: 5px;
      padding-top: 9px;

      @media (min-width: $screen-lg-min) {
        width: 530px;
        float: right;
      }

      figure {
        width: auto;
        height: auto;
        margin-bottom: 5px;
      }
    }

    .col-6 {
      &.welcome-img {
        @media (max-width: $screen-sm-min) {
          padding: 0 40px 40px;
        }
        @media (min-width: $screen-lg-min) {
          padding-right: 40px;
        }
      }

      &.welcome-text {
        @media (min-width: $screen-lg-min) {
          padding-right: 120px;
          clear: right;
        }
      }
    }
  }

  .button {
    margin: 30px 0 0;

    p {
      text-align: left;

      a:link, a:visited {
        @extend %btn-brand-primary;
      }
    }
  }

  &.fewo-listing {
    width: 100%;
    padding-top: 25px;
    margin-bottom: 30px;

    .neos-nodetypes-image {
      position: relative;
      margin-bottom: 30px;

      img {
          width: 100%;
          object-fit: cover;
      }

      figcaption {
        @include transition(.7s);
        opacity: 1;
        background-color: transparentize($brand-primary, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        h2, p {
          color: #fff;
        }

        h2 {
          overflow-wrap: break-word;
          word-wrap: break-word;
          hyphens: auto;
          margin: 35% auto 0 auto;
          width: 80%;
          
          @media (max-width: $screen-lg-min) {
            margin: 7% auto 0 auto;
          }
          
          @media (max-width: $screen-sm-min) {
            margin: 13% auto 0 auto;
          }

          @media (max-width: 320px) {
            margin: 10% auto 0 auto;
          }
        }

        p {
          font-size: 25px;
          text-align: center;
          padding-bottom: 15px;
          width: 50%;
          margin: auto;
          
          @media (max-width: $screen-lg-min) {
            width: 75%;
            line-height: 34px;
            padding-bottom: 8px;
          }

          a {
            margin-top: 10px;
            display: inline-block;
            background-color: #fff;
            padding: 10px 35px;
            border-radius: 10px;
            text-transform: uppercase;
            color: $color-medium-gray;
            font-size: 19px;
            letter-spacing: 1px;
          }
        }
      }

      @media (min-width: $screen-lg) {
        &:hover figcaption {
          @include transition(.7s);
          opacity:1;
          background-color: transparentize($brand-primary, 0.3);
          width: 100%;
          height: 100%;
        }

        figcaption {
          opacity: 0;
        }
      }
    }
  }

  &.lightbox-thumbs {
    width: 100%;
    padding-top: 25px;
    margin-bottom: 0;
    padding-right: 100px !important;
    padding-left: 100px !important;

    &.arrow-down {
      padding-bottom: 118px;
    }

    @media (min-width: $screen-lg) {
      .haus-alpenkoenigat-thumbimage {
        position: relative;
        margin-bottom: 30px;

        &:hover figcaption {
          @include transition(.7s);
          opacity:1;
          background-color: transparentize($brand-primary, 0);
          width: 50%;
          height: auto;
        }

        figcaption {
          position: absolute;
          left: 0;
          top: 0;
          opacity:0;

          p {
            color: #fff;
          }

          p {
            font-family: $font-family-sans-serif;
            font-size: 25px;
            text-align: center;
            padding-bottom: 15px;
            width: 90%;
            margin: 20% auto;
          }
        }
      }
    }
    
    @media (max-width: $screen-md) {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  .haus-alpenkoenigat-thumbimage {
    margin-bottom: 0;
  }

  &.video-thumbs {
    margin-top: 30px;
    margin-bottom: 30px;

    &.arrow-down {
      padding-bottom: 118px;
    }
  }

  &.arrow-down {
    background: url('../Images/layout/arrow-down-green.svg') bottom center no-repeat;
    background-size: 22px 78px;
    padding-bottom: 78px;
  }
  
  &.noMb {
    padding-bottom: 0;
  }

  &.noMt {
    padding-top: 0;
  }
}

.stubaicard {
  max-width: 100%;
  height: 413px;
  margin: 15px;
  border: 1px solid $color-light-gray;

  @media (min-width: $screen-sm-min) {
    margin: 0;
    height: 387px;
  }

  h2, h3 {
    color: $color-medium-gray;
  }

  .neos-nodetypes-text {
    margin-top: 100px;
  }

  .neos-nodetypes-image {
    margin-top: 50px;

    img {
      width: 120px;
      margin: auto;
    }
  }
}

.grid-content-col {
  &.col-12 {
    p {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: $screen-md-min) {
        max-width: 700px;
      }
    }
    &.shadow-box {
      max-width: 520px;
      margin-top: 20px;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;
      padding: 10px;
      box-shadow: $shadow-teaser;

      p {
        padding: 0 25px;
        margin-top: 25px;
      }

      h2 {
        margin: 0;
        padding: 10px 0 5px;
        line-height: 1;
        vertical-align: middle;

        @include line-splitted-bottom();
        @include line-splitted-top-bottom();

        strong {
          color: $color-black;
        }
      }
      img {
        padding: 10px 0;
        margin: 0 auto;
      }

      .line-splitted-top-bottom {
        max-width: 500px;
      }
    }
  }
  
  &.col-4, &.col-5, &.col-6, &.col-7 {
    @media (max-width: $screen-sm) {
      width: 100%;
    }
  }
  
  &.col-6 {
    @media (max-width: $screen-sm) {
      padding-left: 15px !important;
    }
  }
  
  &.col-8 {
    @media (max-width: $screen-md) {
      width: 100%;
    }
  }
  
  &.marginTop {
    margin-top: 20px;
  }
  
  @media (max-width: $screen-sm) {
    &:has(~.grid-content-col) {
      margin-bottom: 30px;
    }
  }
  
  &.paddingLeft15 {
    padding-left: 15px !important;
  }
}
