.neos-nodetypes-text,
.neos-nodetypes-image,
.haus-alpenkoenigat-thumbimage {
    @include line-splitted-bottom();
    @include line-splitted-top-bottom();

    img {
        width: 100%;
        object-fit: cover;
    }
}

.neos-nodetypes-headline {
    &.no-margin-top {
        h1,
        h2,
        h3,
        h4,
        p,
        ul,
        li,
        a {
            margin-top: 0;
        }
    }

    &.colorPurple {
        h3,
        h4 {
            color: $color-purple-02;
        }
    }

    h3 {
        line-height: 26px;
        font-weight: $font-weight-extra-bold;
        font-size: 22px;
    }

    &.textalignLeft {
        h1,
        h2,
        h3,
        h4 {
            text-align: left;
        }
    }

    &.h2Likeh1 {
        h2 {
            font-size: $font-size-h1;
            margin-top: 60px;
        }
    }

    &.h2Likeh3 {
        h2 {
            line-height: 26px;
            font-weight: $font-weight-extra-bold;
            font-size: 22px;
        }
    }
}

.neos-nodetypes-text {
    &.quote {
        h3,
        p {
            color: $color-purple-02;
        }
    }

    &.bulletTable {
        ul {
            list-style: none;
            margin-left: 0;
            padding-left: 0;
            margin-bottom: 40px;

            li {
                padding: 10px 0;
                border-top: 1px solid $color-purple;

                &:last-child {
                    padding: 10px 0;
                    border-bottom: 1px solid $color-purple;
                }
            }
        }
    }
    
    &.button {
        a {
            text-decoration: none;
        }
    }
    
    p {
        a {
            text-decoration-color: $color-purple;
            text-decoration: underline;
            text-underline-offset: 3px;
            text-decoration-thickness: 1px;
        }
    }
}
