body {
  > .content {
    margin: 0 auto 0;
    min-height: calc(100vh);

    img {
      @extend .img-responsive;
    }

    ul {
      padding-left: 20px;
    }

    table {
      @extend .table;
    }
  }
}

body {
  font-weight: $font-weight-light;
  letter-spacing: 0.1px;
}

strong {
  font-weight: 500;
}

h1 {
  margin-top: 0;
  font-weight: $font-weight-medium;
}

h1, h2 {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-medium;
  margin-bottom: 45px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  text-align: left;
  color: $color-black-font;
  
  @media (max-width: $screen-sm) {
    margin-bottom: 25px;
    font-size: 35px;
    line-height: 38px;
  }
}

h2, h3, h4, h5 {
  font-family: $font-family-sans-serif;
  color: $color-black-font;
  line-height: $line-height-base;
  
  em {
    font-style: normal;
    font-weight: $font-weight-light;
  }
}

h2 {
  margin-top: 0;
  line-height: 40px;
  font-weight: $font-weight-bold;
}

h3 {
  line-height: 32px;
  font-weight: $font-weight-extra-bold;
  font-size: 22px;
}