$header-logo-height-md: 68px;
$header-logo-height-xs: 36px;

@import "../themes/_header_base";
@include header-base;

.header {
    position: fixed;
    width: 100%;
    z-index: 20;
    background-color: rgba($color: white, $alpha: 0.95);
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    color: $color-black;
    padding: 15px 0;
    
    @media (max-width: $screen-md) {
    //    height: auto !important;
    }

    .header-content {
        @extend .container;

        @media (max-width: $screen-md) {
            padding: 0;
            margin: 0;
            width: 100%;
        }
    }

    .row {
        margin-left: 0;
        margin-right: 0;
        display: flex;
        align-items: center;
    }

    .header-logo {
        @media (max-width: $screen-md) {
            padding-left: 20px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 99;

            img {
                height: 62px;
            }
        }

        @media (max-width: $screen-sm) {
            img {
                height: 50px;
            }
        }
    }

    @media (max-width: $screen-md) {
        position: fixed;
        top: 0;
        height: 70px;
        width: 100%;
        z-index: 99;
    }
}

.notification + .header-nav {
    top: auto;
}

.header-nav {
    @extend .container;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-medium;
    font-size: 18px;
    color: #fff;
    line-height: 1.166666667;
    z-index: 120;
    display: contents;

    @media (min-width: $screen-md-min) {
        height: 80px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        font-size: 15px;
    }

    @media (max-width: $screen-sm-max) {
        padding: 0;
        width: 100%;
        z-index: 20;
        display: block;
    }
}

.header-nav-toggle {
    position: absolute;
    right: 0;
    z-index: 110;

    span {
        background-color: $color-black;
    }
    @media (max-width: $screen-md) {
        top: 50%;
        transform: translateY(-50%);
    }
}

.navbar-toggle {
    @media (max-width: $screen-sm) {
        padding: 2px 0;
    }
}

.navbar-toggle .icon-bar {
    width: 35px;
    height: 4px;
}

.header-nav-list {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;

    @media (min-width: $screen-md-min) {
        margin-left: 45px;
    }

    @media (max-width: $screen-sm-max) {
        background-color: white;
        padding-bottom: 25px;
        flex-direction: column;
        margin-top: -50px;
        padding-top: 98px;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.16);
    }

    li {

        @media (max-width: $screen-sm-max) {
            width: 100%;
            text-align: center;
            margin: 15px auto;
        }

        @media (min-width: $screen-md) {
            display: inline-block;
            line-height: 80px;

            + li {
                margin-left: 20px;
            }
        }

        @media (min-width: $screen-lg-min) {
            + li {
                margin-left: 47px;
            }
        }

        a {
            color: $color-black;
            font-weight: $font-weight-light;
        }

        &.current,
        &.active {
            color: $color-black;
            font-weight: $font-weight-bold;

            a {
                color: inherit;
            }
        }
    }
    .nav-logo {
        position: relative;
        height: 75px;
        width: 80px;
        background-image: url("../Images/layout/alpenkoenig_typo_Nav+footer.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: center;

        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }
}
