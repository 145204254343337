@media (min-width: $screen-sm) {
  html:not(.wf-active):not(.wf-inactive):not(.wf-cached) {
    body > * {
      visibility: hidden;
    }

    .header,
    .callout {
      visibility: visible;
    }

    .header-nav-content,
    .callout-caption-box > * {
      visibility: hidden;
    }
  }
}


/* hind-siliguri-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 300;
  src: url('../Fonts/hind-siliguri-v13-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* hind-siliguri-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 400;
  src: url('../Fonts/hind-siliguri-v13-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* hind-siliguri-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 500;
  src: url('../Fonts/hind-siliguri-v13-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* hind-siliguri-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 600;
  src: url('../Fonts/hind-siliguri-v13-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* hind-siliguri-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Hind Siliguri';
  font-style: normal;
  font-weight: 700;
  src: url('../Fonts/hind-siliguri-v13-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}