//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: (1140px + $grid-gutter-width);
$grid-float-breakpoint: 992px;

// Fonts
$font-family-sans-serif: 'Hind Siliguri', sans-serif;
$font-family-special: "Leckerli One";
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;
$font-size-base: 19px;
$line-height-base: 1.6;
$font-size-h1: 42px;
$font-size-h2: $font-size-h1 - 12px;
$font-size-h3: $font-size-base + 5px;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$headings-font-weight: 500;
$headings-line-height: 1.19047619;

// Colors
$color-purple: #95bd23;
$color-black: #282828;
$color-gray: #EEECF0;
$color-light-gray: #aaaaaa;
$color-light-gray-pruple: #E0DCE3;
$color-medium-gray: #707070;
$color-white: #ffffff;
$color-purple-light: #865688;
$color-purple: #554164;
$color-purple-02: #492960;
$color-partner-widget: #865688;
$color-black-font: #474748;
$brand-primary: $color-purple;
$brand-secondary: $color-black;
$text-color: $color-black;
$link-color: $brand-primary;
$link-hover-color: lighten($link-color, 10%);
$link-hover-decoration: none;

@if $site == alpenprinzessin {
  $brand-primary: #865688;
  $text-color: $color-medium-gray;
  $link-color: $brand-primary;
  $color-partner-widget: $color-purple;
}

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: $brand-secondary;
$well-color: $color-white;
$input-border-focus: $brand-primary;

// Animations
$animation-duration-normal: 500ms;

// Shadows
$shadow-color: lighten($color-black, 50%);
$shadow-img: -3px 2px 4px $shadow-color;
$shadow-teaser: 0 0 10px $shadow-color;
