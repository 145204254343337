.widget-wrapper {
  position: fixed;
  top: 70%;
  right: 0;
  z-index: 100;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 195px;
  
  .widget-booking, .widget {
    img {
      margin-bottom: 10px;
      width: 28px;
      height: auto;
    }
  }

  .widget {
    width: 100%;
    display: none;
    background-color: $color-purple-02;
    padding: 15px;
    height: auto;
    margin-bottom: 15px;
    color: #ffffff;
    text-align: center;
    
    img {
      width: 30px;
    }

    &.widget-reverse {
      background-color: $color-partner-widget;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-size: 16px;

      span {
        display: block;
        line-height: 20px;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-weight: $font-weight-normal;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .widget {
      display: block;
    }
  }
}
