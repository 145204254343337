.callout {
    .headstart-nodetypes-callout-video {
        @media (max-width: $screen-md-min) {
            background-color: transparentize($color-purple, 0.3) !important;
        }
        .callout-video {
            width: 100%;
            height: 100%;
        }
    }

    padding-top: 100px;
    
    @media (max-width: $screen-md) {
        padding-top: 66px;
    }
    
    &:has(.carousel-inner .item) {
        padding-top: 0;
    }

    &.full {
        @media (min-width: $screen-sm-min) {
            .item {
                height: calc(100vh);
            }
        }

        @media (max-width: $screen-md) {
            .item {
                height: 430px;
            }
        }

        @media (max-width: $screen-xs-max) {
            .item {
                height: 260px;
            }
        }

        .carousel-control-icon {
            &.left {
                background-position: 20% 35%;
            }

            &.right {
                background-position: 70% 35%;
            }
        }
    }

    &:not(.full) {
        .callout-video,
        .jonnitto-prettyembed-wrapper {
            height: 100%;
        }

        video {
            height: 650px;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .item {
        height: 650px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-white;
        margin: 0 auto;
        overflow: hidden;

        @media (max-width: $screen-md) {
            height: 300px;
        }

        @media (max-width: $screen-sm) {
            height: 260px;
        }
    }

    .carousel-control {
        background-image: none;

        @media (max-width: $screen-md) {
            height: 80px;
            top: 50%;
        }
    }

    .carousel-control-icon {
        background-repeat: no-repeat;
        background-size: 45px;
        transition: opacity 0.1s;
        opacity: 1;

        &:hover {
            opacity: 1;
        }

        &.left {
            background-image: url("../Images/layout/callout-arrow-left.svg");
            background-position: 20% 40%;
            background-size: 70px;
            @media (max-width: $screen-xs-max) {
                background-size: 40px;
            }
        }

        &.right {
            background-image: url("../Images/layout/callout-arrow-right.svg");
            background-position: 70% 40%;
            background-size: 70px;
            @media (max-width: $screen-xs-max) {
                background-size: 40px;
            }
        }
    }
    .carousel-indicators {
        li {
            margin: 0 5px;
            width: 15px;
            height: 15px;
            @media (max-width: $screen-xs-max) {
                width: 10px;
                height: 10px;
            }
        }
        .active {
            margin: 0 5px;
            width: 15px;
            height: 15px;
            @media (max-width: $screen-xs-max) {
                width: 12px;
                height: 12px;
            }
        }
    }

    .jonnitto-prettyembed-wrapper {
        height: 100%;

        .jonnitto-prettyembed--video {
            height: 100%;

            video {
                height: 100%;
                object-fit: cover;
            }
        }
    }
}

.callout-caption {
    position: relative;
    height: 100%;
}

.callout-caption-box {
    color: $color-white;
    display: block;
    position: relative;
    margin: 0 auto;
    text-align: center;
    top: 250px;
    width: 100%;
    padding: 32px 20px;
    
    &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: -10px;
        background-image: url('../Images/layout/Logo-Krone.svg');
        background-repeat: no-repeat;
        background-size: contain;
        height: 30px;
        width: 40px;
        
        @media (max-width: 1440px) {
            top: -20px;
        }
    }
    
    @media (max-width: 1440px) {
        background-color: rgba($color: $color-light-gray-pruple, $alpha: 0.9);
        p {
            color: $color-purple-02 !important;
        }
    }

    @media (min-width: $screen-md-min) {
        width: 55%;
    }

    @media (max-width: $screen-md) {
        display: none;
    }

    p {
        color: $color-white;
        font-size: 30px;
        line-height: 38px;
        
        &:first-child {
            font-weight: $font-weight-medium;
        }

        &:last-child {
            font-weight: $font-weight-light;
            margin-bottom: 0;
        }
    }

    h1,
    p:has(strong) {
        font-family: $font-family-sans-serif;
        font-size: 26px;
        line-height: 1.093333333;
        letter-spacing: 2px;
        color: inherit;
        color: $brand-primary;
        margin-bottom: 15px;
        margin-top: 28px !important;
        font-weight: 500;
        @media (min-width: $screen-md-min) {
            font-size: 36px;
        }
    }

    h2 {
        font-size: 18px;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        letter-spacing: 1px;
        @media (min-width: $screen-md-min) {
            font-size: 22px;
        }
    }
}

.callout-button-alpenkoenig {
    display: none;
    position: absolute;
    top: 45%;
    left: 254px;
    width: 20%;
    max-width: 400px;

    @media (min-width: $screen-sm-min) {
        display: block;
    }

    @media (max-width: 1440px) {
        top: 45%;
        left: 220px;
        width: 20%;
    }

    @media (max-width: 1024px) {
        top: 52%;
        left: 360px;
        width: 28%;
    }

    @media (max-width: 768px) {
        top: 12%;
        left: 62px;
        width: 26%;
    }

    @media (max-width: 425px) {
        top: -10%;
        left: 55px;
        width: 54%;
    }

    @media (max-width: 375px) {
        top: -8%;
        left: 46px;
        width: 52%;
    }

    @media (max-width: 320px) {
        left: 44px;
        width: 57%;
    }
}
